import { Dispatch, SetStateAction, createContext, useContext } from 'react'
import { ITemplate } from '../../../interfaces/templates.interface'

const contextInitial = {
  currentItem: undefined,
  selected: undefined,
  setCurrentItem: () => undefined,
}

export interface IElementsContext {
  currentItem: ITemplate | undefined
  selected: number | undefined
  setCurrentItem: Dispatch<SetStateAction<ITemplate>>
}

export const ElementsContext = createContext<IElementsContext>(contextInitial)
export const useElementsContext = (): IElementsContext => useContext(ElementsContext)
