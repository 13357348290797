import React, { useEffect, useMemo, useState } from 'react'
import cls from './DocEditor.module.scss'
import { ElementsContext } from '../../context/elementsContext'
import { View } from './components/View/View'
import { Header } from './components/Header/Header'
import { EditorDoc } from './components/EditorDoc/EditorDoc'
import { Preview } from './components/Preview/Preview'
import axiosInstance from '../../../../config/axios'
import { serverPath } from '../../../../constants/api-endpoints'
import { errorMapper } from '../../../../utils/errorMapper'
import { ErrorMessages } from '../../../../constants/errors'
import { useMessage } from '../../../../hooks/useMessage'
import usePermissions from '../../../../hooks/usePermissions'
import { EUserRoles } from '../../../../enums/roles'
import { useDebounce } from '../../../../hooks/useDebounce'
import { ITemplate } from '../../../../interfaces/templates.interface'

interface IProps {
  item: ITemplate
}

export const DocEditor: React.FC<IProps> = ({ item }) => {
  const [currentItem, setCurrentItem] = useState<ITemplate>({
    ...item,
    template: item.template ?? [],
  })
  const debounce = useDebounce(currentItem, 500)

  const [selected, setSelected] = useState<number>()
  const { error } = useMessage()
  const isAdminPermission = usePermissions([
    EUserRoles.ADMIN,
    EUserRoles.SUPER_ADMIN,
    EUserRoles.OPERATOR,
  ])

  const selectedElement = useMemo(
    () =>
      currentItem?.template?.length > 0
        ? currentItem?.template?.find((el) => el?.id === selected)
        : undefined,
    [currentItem, selected],
  )

  const updateDoc = async () => {
    try {
      await axiosInstance.put<ITemplate>(serverPath.TEMPLATES + '/' + item.id, currentItem)
    } catch (e) {
      error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.SERVER_ERROR)
    }
  }

  useEffect(() => {
    isAdminPermission && updateDoc()
  }, [debounce])

  return (
    <ElementsContext.Provider value={{ currentItem, selected, setCurrentItem }}>
      <div className={cls.DocEditor}>
        <Preview current={currentItem} />
        <div className={cls.body}>
          <View selected={selected} setSelected={setSelected} />
          <div className={cls.editWrapper}>
            <Header selected={selected} setSelected={setSelected} item={item} />
            <EditorDoc element={selectedElement} />
          </div>
        </div>
        <div className={cls.list}></div>
      </div>
    </ElementsContext.Provider>
  )
}
