import { BankName } from '../enums/banks'
import { ImgType } from '../pages/DocGenPreview/components/DocEditor/constants/img'

export enum ElementType {
  TEXT = 'text',
  IMG = 'image',
  LOGO = 'logo',
  STAMP = 'stamp',
  QR = 'qr',
}

export interface IElem {
  id: number
  label: string
  type: ElementType
  imgType?: ImgType
  imgUrl?: string
  bankId?: string
  hidden: boolean
  value: string
  width?: number
  rotate?: number
  zIndex?: number
  x: number
  y: number
}
export interface ITemplate {
  createdAt: string
  crmId: string
  id: string
  bank: BankName
  name: string
  template: IElem[]
  updatedAt: string
  shareHash: string | null
  categoryId?: string
  countryId?: string
}

export interface IBackTemplate extends Omit<ITemplate, 'template'> {
  template: string
}

export interface IDocCategory {
  id: string
  name: string
  createdAt: string
}

export interface IDocMedia {
  id: string
  url: string
  fileName: string
  name: string
  type: ImgType
  // countryId: string
  createdAt: string
}

export interface IDocBank {
  id: string
  name: string
  logoId: string
  logo?: IDocMedia
  qrCodeId?: string
  qrCode?: IDocMedia
  signId: string
  sign?: IDocMedia
  address?: string
  details?: string
  supervisor?: string
  footer1?: string
  footer2?: string
  footer3?: string
  footer4?: string
  footer5?: string
  bik?: string
  inn?: string
  kpp?: string
  korr?: string
  createdAt: string
  updatedAt: string
}
