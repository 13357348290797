import ButtonUi from '../../../../../../../../components/ui/ButtonUi/ButtonUi'
import { classNames } from '../../../../../../../../utils/classNames'
import { useElementsContext } from '../../../../../../context/elementsContext'
import { bankVarArr } from '../../../../banks/variables'
import cls from './BankSelect.module.scss'
import React, { useCallback, useMemo } from 'react'
import { BankName } from '../../../../../../../../enums/banks'
import { docPreset } from '../../../../../../../../constants/docs/docPreset'
import { Collapse, Select } from 'antd'
import { useBanksSelector } from '../../../../../../../../store/selectors/settings'
import { ElementType } from '../../../../../../../../interfaces/templates.interface'

interface BankSelectProps {
  className?: string
}

const { Option } = Select
const { Panel } = Collapse

const optionInit = Object.entries(BankName).map((el) => ({
  value: el[1],
  label: docPreset[el[1]].name ?? '',
}))

export const BankSelect: React.FC<BankSelectProps> = ({ className = '' }) => {
  const { currentItem, selected, setCurrentItem } = useElementsContext()
  const apiBanks = useBanksSelector()

  const bankList = useMemo(() => {
    return [...(apiBanks || []).map((el) => ({ value: el.id, label: el.name })), ...optionInit]
  }, [apiBanks])

  const addImgElementHandler = (type: ElementType) => {
    const id = Date.now()
    setCurrentItem((prev) => {
      return {
        ...prev,
        template: [
          ...prev.template,
          {
            id,
            label: String(id),
            value: prev.bank,
            type,
            hidden: false,
            width: 40,
            x: 10,
            y: 10,
          },
        ],
      }
    })
  }

  const addVariable = useCallback(
    (variable: string) => {
      const current = currentItem?.template?.find((el) => el.id === selected)
      const filtered = currentItem?.template?.filter((el) => el.id !== selected)
      if (current && current.type === ElementType.TEXT) {
        setCurrentItem((prev) => ({
          ...prev,
          template: [
            ...(filtered ?? []),
            { ...current, value: current.value + `<p>${variable}</p>` },
          ],
        }))
      }
    },
    [selected, currentItem, setCurrentItem],
  )

  const setBankHandler = (e: BankName) => {
    if (currentItem?.bank !== e) {
      setCurrentItem((prev) => ({
        ...prev,
        bank: e,
      }))
    }
  }

  const btnNames = useMemo(() => bankVarArr(), [])

  return (
    <div className={classNames([cls.BankSelect, className])}>
      <Collapse>
        <Panel className={cls.collaps} header='Настройки банка' key='1'>
          <>
            <Select
              className={cls.bankSelect}
              allowClear={false}
              onChange={setBankHandler}
              placeholder='Банк'
              showSearch
              value={currentItem?.bank}
              defaultValue={currentItem?.bank}
              style={{ minWidth: 200 }}
              filterOption={(input, option) =>
                option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {bankList.map((el) => (
                <Option value={el.value} key={el.value}>
                  {el.label}
                </Option>
              ))}
            </Select>

            <div className={cls.row}>
              <ButtonUi
                size='small'
                color='green'
                onClick={() => addImgElementHandler(ElementType.LOGO)}
              >
                ЛОГО БАНКА
              </ButtonUi>
              <ButtonUi
                size='small'
                color='green'
                onClick={() => addImgElementHandler(ElementType.STAMP)}
              >
                ПЕЧАТЬ БАНКА
              </ButtonUi>
              <ButtonUi
                size='small'
                color='green'
                onClick={() => addImgElementHandler(ElementType.QR)}
              >
                QR БАНКА
              </ButtonUi>
              {btnNames.map((el) => {
                return (
                  <ButtonUi size='small' key={el} onClick={() => addVariable(el)}>
                    {el}
                  </ButtonUi>
                )
              })}
            </div>
          </>
        </Panel>
      </Collapse>
    </div>
  )
}
