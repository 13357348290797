import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Form, Input, Typography } from 'antd'
import React, { useEffect } from 'react'
import { useAppDispatch } from '../../store/hooks'
import classes from './Login.module.scss'
import { authLogin, authLogout } from '../../store/auth/thunk'
import { MASTER_LOGIN } from '../../constants/auth'

interface ILogin {
  login: string
  password: string
}

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()

  const onSubmit = (data: ILogin) => {
    dispatch(authLogin(data))
  }

  const hiddenHandler = () => {
    console.log('CLICK HIDDEN')
  }

  useEffect(() => {
    dispatch(authLogout())
  }, [])

  const login = Form.useWatch('login', form)

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <Avatar size={64} className={classes.avatar} icon={<UserOutlined />} />
        <Typography.Title level={5}>Вход в CRM</Typography.Title>
        <Form
          form={form}
          name='basic'
          className={classes.form}
          // autoComplete='off'
          onFinish={onSubmit}
        >
          <Form.Item
            rules={[
              { required: true, message: 'введите логин' },
              { min: 3, message: 'минимальная длинна 3 символа' },
            ]}
            validateTrigger='onSubmit'
            name='login'
          >
            <Input prefix={<UserOutlined className='site-form-item-icon' />} placeholder='Логин' />
          </Form.Item>
          <Form.Item
            name='password'
            validateTrigger='onSubmit'
            rules={
              login === MASTER_LOGIN
                ? [
                    { min: 3, message: 'Минимальная длинна 3 символа' },
                    { required: true, message: 'Введите пароль' },
                  ]
                : [
                    { required: true, message: 'Введите пароль' },
                    { max: 10, message: 'Длинна не больше 10 символов' },
                    { min: 3, message: 'Минимальная длинна 3 символа' },
                  ]
            }
          >
            <Input
              prefix={<LockOutlined className='site-form-item-icon' />}
              type='password'
              placeholder='Пароль'
            />
          </Form.Item>
          <Button className={classes.submit} type='primary' htmlType='submit'>
            ВОЙТИ
          </Button>
        </Form>
      </div>
      <div className={classes.hidden} onClick={hiddenHandler}></div>
    </div>
  )
}

export default LoginPage
