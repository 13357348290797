import { useCallback, useMemo } from 'react'
import { bankVariables } from '../banks/variables'
import { docPreset } from '../../../../../constants/docs/docPreset'
import { useElementsContext } from '../../../context/elementsContext'
import { useBanksSelector } from '../../../../../store/selectors/settings'
import { BankName } from '../../../../../enums/banks'
import { ITemplate } from '../../../../../interfaces/templates.interface'

const useBankParser = (current?: ITemplate) => {
  const apiBanks = useBanksSelector()

  const { currentItem } = useElementsContext()
  const apiBank = useMemo(() => {
    if (currentItem) {
      return apiBanks.find((el) => el.id === currentItem.bank)
    }
    return apiBanks.find((el) => el.id === current?.bank)
  }, [apiBanks, current, currentItem])
  const bank = currentItem?.bank ?? current?.bank
  const textParser = useCallback(
    (text: string) => {
      if (!bank && !apiBank) {
        return text
      }
      if (apiBank) {
        return text
          .replaceAll(bankVariables.ADDRESS, apiBank?.address ?? '')
          .replaceAll(bankVariables.NAME, apiBank?.name ?? '')
          .replaceAll(bankVariables.RECS, apiBank?.details ?? '')
          .replaceAll(bankVariables.RUC, apiBank?.supervisor ?? '')
          .replaceAll(bankVariables.FOOTER1, apiBank?.footer1 ?? '')
          .replaceAll(bankVariables.FOOTER2, apiBank?.footer2 ?? '')
          .replaceAll(bankVariables.FOOTER3, apiBank?.footer3 ?? '')
          .replaceAll(bankVariables.FOOTER4, apiBank?.footer4 ?? '')
          .replaceAll(bankVariables.FOOTER5, apiBank?.footer5 ?? '')
          .replaceAll(bankVariables.BIK, apiBank?.bik ?? '')
          .replaceAll(bankVariables.INN, apiBank?.inn ?? '')
          .replaceAll(bankVariables.KPP, apiBank?.kpp ?? '')
          .replaceAll(bankVariables.KORR, apiBank?.korr ?? '')
          .replaceAll(bankVariables.DATE, new Date().toLocaleDateString())
      }
      if (bank) {
        return text
          .replaceAll(bankVariables.ADDRESS, docPreset[bank].address)
          .replaceAll(bankVariables.NAME, docPreset[bank].name)
          .replaceAll(bankVariables.RECS, docPreset[bank].recs)
          .replaceAll(bankVariables.RUC, docPreset[bank].valueruc)
          .replaceAll(bankVariables.FOOTER1, docPreset[bank].footer1)
          .replaceAll(bankVariables.FOOTER2, docPreset[bank].footer2)
          .replaceAll(bankVariables.FOOTER3, docPreset[bank].footer3)
          .replaceAll(bankVariables.FOOTER4, docPreset[bank].footer4)
          .replaceAll(bankVariables.FOOTER5, docPreset[bank].footer5)
          .replaceAll(bankVariables.BIK, docPreset[bank].bik)
          .replaceAll(bankVariables.INN, docPreset[bank].inn)
          .replaceAll(bankVariables.KPP, docPreset[bank].kpp)
          .replaceAll(bankVariables.KORR, docPreset[bank].korr)
          .replaceAll(bankVariables.DATE, new Date().toLocaleDateString())
      }

      return text
    },
    [bank, apiBank],
  )

  const logoParser = useCallback((bankId: string) => {
    let url = docPreset[bankId as BankName]?.img ?? ''
    if (!url) {
      const currentBank = apiBanks?.find((el) => el.id === bankId)
      url = currentBank?.logo?.url ?? ''
    }
    return url
  }, [])

  const stampParser = useCallback((bankId: string) => {
    let url = docPreset[bankId as BankName]?.stamp ?? ''
    if (!url) {
      const currentBank = apiBanks?.find((el) => el.id === bankId)
      url = currentBank?.sign?.url ?? ''
    }
    return url
  }, [])

  const qrParser = useCallback((bankId: string) => {
    let url = ''
    if (!url) {
      const currentBank = apiBanks?.find((el) => el.id === bankId)
      url = currentBank?.qrCode?.url ?? ''
    }
    return url
  }, [])

  return { textParser, logoParser, stampParser, qrParser }
}

export default useBankParser
