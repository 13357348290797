import { ElementType, ITemplate } from '../../../../../../interfaces/templates.interface'
import { classNames } from '../../../../../../utils/classNames'
import useBankParser from '../../hooks/useBankParser'
import cls from './Preview.module.scss'
import React, { useMemo } from 'react'

interface PreviewProps {
  className?: string
  current: ITemplate
}

export const Preview: React.FC<PreviewProps> = ({ current, className = '' }) => {
  const { textParser, logoParser, stampParser, qrParser } = useBankParser(current)

  const qrSrc = useMemo(() => {
    return qrParser(current?.bank ?? '')
  }, [current?.bank])

  return (
    <div id='divToPrint' className={classNames([cls.Preview, className])}>
      {current?.template?.map((element) => {
        switch (element.type) {
          case ElementType.TEXT:
            return (
              <div
                key={element.id}
                className={cls.element}
                style={{
                  left: `${element.x}px`,
                  top: `${element.y}px`,
                  width: `${element.width}%`,
                  zIndex: element.zIndex ?? 2,
                }}
                dangerouslySetInnerHTML={{ __html: textParser(element.value ?? current) }}
              />
            )
          case ElementType.IMG: {
            return (
              <img
                key={element.id}
                className={classNames([cls.element])}
                style={{
                  left: `${element.x}px`,
                  top: `${element.y}px`,
                  width: `${element.width ?? 40}%`,
                  transform: `rotate(${element.rotate}deg)`,
                  zIndex: element.zIndex ?? 2,
                }}
                src={element.imgUrl ?? element.value ?? ''}
                alt='image'
              />
            )
          }
          case ElementType.LOGO:
            return (
              <img
                key={element.id}
                className={classNames([cls.element])}
                style={{
                  left: `${element.x}px`,
                  top: `${element.y}px`,
                  width: `${element.width ?? 40}%`,
                  transform: `rotate(${element.rotate}deg)`,
                  zIndex: element.zIndex ?? 2,
                }}
                src={logoParser(current?.bank ?? '')}
                alt='logo'
              />
            )
          case ElementType.STAMP:
            return (
              <img
                key={element.id}
                className={classNames([cls.element])}
                style={{
                  left: `${element.x}px`,
                  top: `${element.y}px`,
                  width: `${element.width ?? 40}%`,
                  transform: `rotate(${element.rotate}deg)`,
                  zIndex: element.zIndex ?? 2,
                }}
                src={stampParser(current?.bank ?? '')}
                alt='stamp'
              />
            )
          case ElementType.QR:
            return (
              <img
                key={element.id}
                className={classNames([cls.element])}
                style={{
                  left: `${element.x}px`,
                  top: `${element.y}px`,
                  width: `${element.width ?? 40}%`,
                  transform: `rotate(${element.rotate}deg)`,
                  zIndex: element.zIndex ?? 2,
                }}
                src={qrSrc ?? ''}
                alt='QR не установлен'
              />
            )
        }
      })}
    </div>
  )
}
