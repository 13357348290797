import { AdditionType, ImgType, PhotoType, SignatureType, StampType } from './img'
import image from '../../../components/DocEditor/assets/svg/image.svg'

export interface IImgMapper {
  label: string
  src: string
}

export const ImagesMapper: Record<ImgType, string> = {
  [ImgType.FOTO]: 'Фото',
  [ImgType.SIGNATURE]: 'Подписи',
  [ImgType.STAMP]: 'Печати',
  [ImgType.ADDITION]: 'Дополнительные элементы',
  [ImgType.BANK_LOGO]: 'Логотип банка',
  [ImgType.BACKGROUND]: 'Фон',
  [ImgType.QR]: 'QR код',
}

export const AdditionalMapper: Record<AdditionType, IImgMapper> = {
  [AdditionType.BARCODE]: {
    label: 'Штрих Код',
    src: '/bankdocs/stamp/barcode.png',
  },
  [AdditionType.BORDER]: {
    label: 'Контур',
    src: '/bankdocs/additional/border.svg',
  },
  [AdditionType.LINE_H]: {
    label: 'Линия горизонтальная',
    src: '/bankdocs/additional/lineH.png',
  },
  [AdditionType.CIRCLE]: {
    label: 'Круг',
    src: '/bankdocs/additional/circle.png',
  },
  [AdditionType.CIRCLE_D]: {
    label: 'Круг штриховой',
    src: '/bankdocs/additional/circle_dash.png',
  },
  [AdditionType.SQUARE]: {
    label: 'Квадрат',
    src: '/bankdocs/additional/sq.png',
  },
  [AdditionType.SQUARE_D]: {
    label: 'Квадрат штриховой',
    src: '/bankdocs/additional/sqD.png',
  },
}

export const SignatureMapper: Record<SignatureType, IImgMapper> = {
  [SignatureType.SIGN1]: {
    label: 'Подпись1',
    src: '/bankdocs/signature/signature1.png',
  },
  [SignatureType.SIGN2]: {
    label: 'Подпись2',
    src: '/bankdocs/signature/signature2.png',
  },
  [SignatureType.SIGN3]: {
    label: 'Подпись3',
    src: '/bankdocs/signature/signature3.png',
  },
  [SignatureType.SIGN4]: {
    label: 'Подпись4',
    src: '/bankdocs/signature/signature4.png',
  },
  [SignatureType.SIGN5]: {
    label: 'Подпись5',
    src: '/bankdocs/signature/signature5.png',
  },
  [SignatureType.SIGN6]: {
    label: 'Подпись6',
    src: '/bankdocs/signature/signature6.png',
  },
  [SignatureType.SIGN7]: {
    label: 'Подпись7',
    src: '/bankdocs/signature/signature7.png',
  },
  [SignatureType.SIGN8]: {
    label: 'Подпись8',
    src: '/bankdocs/signature/signature8.png',
  },
  [SignatureType.SIGN9]: {
    label: 'Подпись9',
    src: '/bankdocs/signature/signature9.png',
  },
  [SignatureType.SIGN10]: {
    label: 'Подпись10',
    src: '/bankdocs/signature/signature10.png',
  },
  [SignatureType.SIGN11]: {
    label: 'Подпись11',
    src: '/bankdocs/signature/signature11.png',
  },
  [SignatureType.SIGN12]: {
    label: 'Подпись12',
    src: '/bankdocs/signature/signature12.png',
  },
  [SignatureType.SIGN13]: {
    label: 'Подпись13',
    src: '/bankdocs/signature/13.jpg',
  },
  [SignatureType.SIGN14]: {
    label: 'Подпись14',
    src: '/bankdocs/signature/14.jpg',
  },
  [SignatureType.SIGN15]: {
    label: 'Подпись15',
    src: '/bankdocs/signature/15.jpg',
  },
  [SignatureType.SIGN16]: {
    label: 'Подпись16',
    src: '/bankdocs/signature/16.jpg',
  },
  [SignatureType.SIGN17]: {
    label: 'Подпись17',
    src: '/bankdocs/signature/17.jpg',
  },
  [SignatureType.SIGN18]: {
    label: 'Подпись18',
    src: '/bankdocs/signature/18.jpg',
  },
  [SignatureType.SIGN19]: {
    label: 'Подпись19',
    src: '/bankdocs/signature/19.jpg',
  },
  [SignatureType.SIGN20]: {
    label: 'Подпись20',
    src: '/bankdocs/signature/20.jpg',
  },
  [SignatureType.SIGN21]: {
    label: 'Подпись21',
    src: '/bankdocs/signature/21.jpg',
  },
  [SignatureType.SIGN22]: {
    label: 'Подпись22',
    src: '/bankdocs/signature/22.jpg',
  },
  [SignatureType.SIGN23]: {
    label: 'Подпись23',
    src: '/bankdocs/signature/23.jpg',
  },
  [SignatureType.SIGN24]: {
    label: 'Подпись24',
    src: '/bankdocs/signature/24.jpg',
  },
  [SignatureType.SIGN25]: {
    label: 'Подпись25',
    src: '/bankdocs/signature/25.jpg',
  },
  [SignatureType.SIGN26]: {
    label: 'Подпись26',
    src: '/bankdocs/signature/26.jpg',
  },
  [SignatureType.SIGN27]: {
    label: 'Подпись27',
    src: '/bankdocs/signature/27.jpg',
  },
  [SignatureType.SIGN28]: {
    label: 'Подпись28',
    src: '/bankdocs/signature/28.jpg',
  },
  [SignatureType.SIGN29]: {
    label: 'Подпись29',
    src: '/bankdocs/signature/29.jpg',
  },
  [SignatureType.SIGN30]: {
    label: 'Подпись30',
    src: '/bankdocs/signature/30.jpg',
  },
  [SignatureType.SIGN31]: {
    label: 'Подпись31',
    src: '/bankdocs/signature/31.jpg',
  },
  [SignatureType.SIGN32]: {
    label: 'Подпись32',
    src: '/bankdocs/signature/32.jpg',
  },
  [SignatureType.SIGN33]: {
    label: 'Подпись33',
    src: '/bankdocs/signature/33.jpg',
  },
  [SignatureType.SIGN34]: {
    label: 'Подпись34',
    src: '/bankdocs/signature/34.jpg',
  },
  [SignatureType.SIGN35]: {
    label: 'Подпись35',
    src: '/bankdocs/signature/35.jpg',
  },
  [SignatureType.SIGN36]: {
    label: 'Подпись36',
    src: '/bankdocs/signature/36.jpg',
  },
  [SignatureType.SIGN37]: {
    label: 'Подпись37',
    src: '/bankdocs/signature/37.jpg',
  },
  [SignatureType.SIGN38]: {
    label: 'Подпись38',
    src: '/bankdocs/signature/38.jpg',
  },
  [SignatureType.SIGN39]: {
    label: 'Подпись39',
    src: '/bankdocs/signature/39.jpg',
  },
  [SignatureType.SIGN40]: {
    label: 'Подпись40',
    src: '/bankdocs/signature/40.jpg',
  },
  [SignatureType.SIGN41]: {
    label: 'Подпись41',
    src: '/bankdocs/signature/41.jpg',
  },
  [SignatureType.SIGN42]: {
    label: 'Подпись42',
    src: '/bankdocs/signature/42.jpg',
  },
}

export const PhotoMapper: Record<PhotoType | 'BLANK', IImgMapper> = {
  [PhotoType.PHOTO1]: {
    label: 'Фото1',
    src: '/bankdocs/photo/photo1.jpg',
  },
  [PhotoType.PHOTO2]: {
    label: 'Фото2',
    src: '/bankdocs/photo/photo2.jpg',
  },
  [PhotoType.PHOTO3]: {
    label: 'Фото3',
    src: '/bankdocs/photo/photo3.jpg',
  },
  [PhotoType.PHOTO4]: {
    label: 'Фото4',
    src: '/bankdocs/photo/photo4.jpg',
  },
  [PhotoType.PHOTO5]: {
    label: 'Фото5',
    src: '/bankdocs/photo/photo5.jpg',
  },
  [PhotoType.PHOTO6]: {
    label: 'Фото6',
    src: '/bankdocs/photo/photo6.jpg',
  },
  [PhotoType.PHOTO7]: {
    label: 'Фото7',
    src: '/bankdocs/photo/photo7.jpg',
  },
  [PhotoType.PHOTO8]: {
    label: 'Фото8',
    src: '/bankdocs/photo/photo8.jpg',
  },
  [PhotoType.PHOTO9]: {
    label: 'Фото9',
    src: '/bankdocs/photo/photo9.jpg',
  },
  [PhotoType.PHOTO10]: {
    label: 'Фото10',
    src: '/bankdocs/photo/photo10.jpg',
  },
  [PhotoType.PHOTO11]: {
    label: 'Фото11',
    src: '/bankdocs/photo/photo11.jpg',
  },
  [PhotoType.PHOTO12]: {
    label: 'Фото12',
    src: '/bankdocs/photo/photo12.jpg',
  },
  [PhotoType.PHOTO13]: {
    label: 'Фото13',
    src: '/bankdocs/photo/photo13.jpg',
  },
  [PhotoType.PHOTO14]: {
    label: 'Фото14',
    src: '/bankdocs/photo/photo14.jpg',
  },
  [PhotoType.PHOTO15]: {
    label: 'Фото15',
    src: '/bankdocs/photo/photo15.jpg',
  },
  [PhotoType.PHOTO16]: {
    label: 'Фото16',
    src: '/bankdocs/photo/photo16.jpg',
  },
  [PhotoType.PHOTO17]: {
    label: 'Фото17',
    src: '/bankdocs/photo/photo17.jpg',
  },
  [PhotoType.PHOTO18]: {
    label: 'Фото18',
    src: '/bankdocs/photo/photo18.jpg',
  },
  [PhotoType.PHOTO19]: {
    label: 'Фото19',
    src: '/bankdocs/photo/photo19.jpg',
  },
  [PhotoType.PHOTO20]: {
    label: 'Фото20',
    src: '/bankdocs/photo/photo20.jpg',
  },
  [PhotoType.PHOTO21]: {
    label: 'Фото21',
    src: '/bankdocs/photo/photo21.jpg',
  },
  [PhotoType.PHOTO22]: {
    label: 'Фото22',
    src: '/bankdocs/photo/photo22.jpg',
  },
  [PhotoType.PHOTO23]: {
    label: 'Фото23',
    src: '/bankdocs/photo/photo23.jpg',
  },
  [PhotoType.PHOTO24]: {
    label: 'Фото24',
    src: '/bankdocs/photo/photo24.jpg',
  },
  [PhotoType.PHOTO25]: {
    label: 'Фото25',
    src: '/bankdocs/photo/photo25.jpg',
  },
  [PhotoType.PHOTO26]: {
    label: 'Фото26',
    src: '/bankdocs/photo/photo26.jpg',
  },
  [PhotoType.PHOTO27]: {
    label: 'Фото27',
    src: '/bankdocs/photo/photo27.jpg',
  },
  [PhotoType.PHOTO28]: {
    label: 'Фото28',
    src: '/bankdocs/photo/photo28.jpg',
  },
  [PhotoType.PHOTO29]: {
    label: 'Фото29',
    src: '/bankdocs/photo/photo29.jpg',
  },
  [PhotoType.PHOTO30]: {
    label: 'Фото30',
    src: '/bankdocs/photo/photo30.jpg',
  },
  [PhotoType.PHOTO31]: {
    label: 'Фото31',
    src: '/bankdocs/photo/photo31.jpg',
  },
  [PhotoType.PHOTO32]: {
    label: 'Фото32',
    src: '/bankdocs/photo/photo32.jpg',
  },
  [PhotoType.PHOTO33]: {
    label: 'Фото33',
    src: '/bankdocs/photo/photo33.jpg',
  },
  [PhotoType.PHOTO34]: {
    label: 'Фото34',
    src: '/bankdocs/photo/photo34.jpg',
  },
  [PhotoType.PHOTO35]: {
    label: 'Фото35',
    src: '/bankdocs/photo/photo35.jpg',
  },
  [PhotoType.PHOTO36]: {
    label: 'Фото36',
    src: '/bankdocs/photo/photo36.jpg',
  },
  [PhotoType.PHOTO37]: {
    label: 'Фото37',
    src: '/bankdocs/photo/photo37.jpg',
  },
  [PhotoType.PHOTO38]: {
    label: 'Фото38',
    src: '/bankdocs/photo/photo38.jpg',
  },
  [PhotoType.PHOTO39]: {
    label: 'Фото39',
    src: '/bankdocs/photo/photo39.jpg',
  },
  [PhotoType.PHOTO40]: {
    label: 'Фото40',
    src: '/bankdocs/photo/photo40.png',
  },
  [PhotoType.PHOTO41]: {
    label: 'Фото41',
    src: '/bankdocs/photo/photo41.png',
  },
  [PhotoType.PHOTO42]: {
    label: 'Фото42',
    src: '/bankdocs/photo/photo42.png',
  },
  [PhotoType.PHOTO43]: {
    label: 'Фото43',
    src: '/bankdocs/photo/photo43.png',
  },
  [PhotoType.PHOTO44]: {
    label: 'Фото44',
    src: '/bankdocs/photo/photo44.png',
  },
  [PhotoType.PHOTO45]: {
    label: 'Фото45',
    src: '/bankdocs/photo/photo45.jpg',
  },
  [PhotoType.PHOTO46]: {
    label: 'Фото46',
    src: '/bankdocs/photo/photo46.jpg',
  },
  [PhotoType.PHOTO47]: {
    label: 'Фото47',
    src: '/bankdocs/photo/photo47.jpg',
  },
  [PhotoType.PHOTO48]: {
    label: 'Фото48',
    src: '/bankdocs/photo/photo48.jpg',
  },
  [PhotoType.PHOTO49]: {
    label: 'Фото49',
    src: '/bankdocs/photo/photo49.jpg',
  },
  [PhotoType.PHOTO50]: {
    label: 'Фото50',
    src: '/bankdocs/photo/photo50.png',
  },
  [PhotoType.PHOTO51]: {
    label: 'Фото51',
    src: '/bankdocs/photo/photo51.jpg',
  },
  [PhotoType.PHOTO52]: {
    label: 'Фото52',
    src: '/bankdocs/photo/photo52.jpg',
  },
  [PhotoType.PHOTO53]: {
    label: 'Фото53',
    src: '/bankdocs/photo/photo53.jpg',
  },
  [PhotoType.PHOTO54]: {
    label: 'Фото54',
    src: '/bankdocs/photo/photo54.jpg',
  },
  [PhotoType.PHOTO55]: {
    label: 'Фото55',
    src: '/bankdocs/photo/photo55.jpg',
  },
  [PhotoType.PHOTO56]: {
    label: 'Фото56',
    src: '/bankdocs/photo/photo56.jpg',
  },
  [PhotoType.PHOTO57]: {
    label: 'Фото57',
    src: '/bankdocs/photo/photo57.jpg',
  },
  [PhotoType.PHOTO58]: {
    label: 'Фото58',
    src: '/bankdocs/photo/photo58.jpg',
  },
  [PhotoType.PHOTO59]: {
    label: 'Фото59',
    src: '/bankdocs/photo/photo59.jpg',
  },
  [PhotoType.PHOTO60]: {
    label: 'Фото60',
    src: '/bankdocs/photo/photo60.jpg',
  },
  [PhotoType.PHOTO61]: {
    label: 'Фото61',
    src: '/bankdocs/photo/photo61.jpg',
  },
  BLANK: {
    label: 'Пустая картинка',
    src: image,
  },
}

export const StampMapper: Record<StampType, IImgMapper> = {
  [StampType.ABSOLUTE]: {
    label: 'Абсолют',
    src: '/bankdocs/stamp/absolut.png',
  },
  [StampType.AKBARS]: {
    label: 'АкБарс',
    src: '/bankdocs/stamp/akbars.png',
  },
  [StampType.ALFA]: {
    label: 'АЛьФА',
    src: '/bankdocs/stamp/alfa.png',
  },
  [StampType.ATB]: {
    label: 'АТБ',
    src: '/bankdocs/stamp/atb.png',
  },
  [StampType.AVANGARD]: {
    label: 'Авангард',
    src: '/bankdocs/stamp/avangard.png',
  },
  [StampType.BKS]: {
    label: 'БКС',
    src: '/bankdocs/stamp/bks.png',
  },
  [StampType.BSPB]: {
    label: 'БСПБ',
    src: '/bankdocs/stamp/bspb.png',
  },
  [StampType.CBR]: {
    label: 'ЦентроБанк',
    src: '/bankdocs/stamp/cbr.png',
  },
  [StampType.C2BR]: {
    label: 'ЦентроБанк2',
    src: '/bankdocs/stamp/c2br.png',
  },
  [StampType.CITY]: {
    label: 'City',
    src: '/bankdocs/stamp/city.png',
  },
  [StampType.COAST]: {
    label: 'Орел двухглавый',
    src: '/bankdocs/stamp/coast.png',
  },
  [StampType.DOMRF]: {
    label: 'ДомРФ',
    src: '/bankdocs/stamp/domrf.png',
  },
  [StampType.FSB]: {
    label: 'ФСБ',
    src: '/bankdocs/stamp/fsb.png',
  },
  [StampType.FSB2]: {
    label: 'ФСБ2',
    src: '/bankdocs/stamp/fsb2.png',
  },
  [StampType.GAZPROM]: {
    label: 'Газпром',
    src: '/bankdocs/stamp/gazprom.png',
  },
  [StampType.GENBANK]: {
    label: 'ГенБанк',
    src: '/bankdocs/stamp/genbank.png',
  },
  [StampType.HLINOV]: {
    label: 'Хлинов',
    src: '/bankdocs/stamp/hlinov.png',
  },
  [StampType.HOMECREDIT]: {
    label: 'HomeCredit',
    src: '/bankdocs/stamp/homecredit.png',
  },
  [StampType.KAZAH]: {
    label: 'Печать Казахстан',
    src: '/bankdocs/stamp/kazah.png',
  },
  [StampType.LOCKOBANK]: {
    label: 'ЛокоБанк',
    src: '/bankdocs/stamp/locobank.png',
  },
  [StampType.MINBANK]: {
    label: 'МИнБанк',
    src: '/bankdocs/stamp/minbank.png',
  },
  [StampType.MKB]: {
    label: 'МКБ',
    src: '/bankdocs/stamp/mkb.png',
  },
  [StampType.MTS]: {
    label: 'МТС Банк',
    src: '/bankdocs/stamp/mts.png',
  },
  [StampType.MVD_KAZAH]: {
    label: 'МВД Казахстан',
    src: '/bankdocs/stamp/mvd-kazah.png',
  },
  [StampType.MVD]: {
    label: 'МВД1',
    src: '/bankdocs/stamp/mvd.png',
  },
  [StampType.MVD2]: {
    label: 'МВД2',
    src: '/bankdocs/stamp/mvd2.png',
  },
  [StampType.MVD3]: {
    label: 'МВД3',
    src: '/bankdocs/stamp/mvd3.png',
  },
  [StampType.NBK]: {
    label: 'Национальный банк Казахстан',
    src: '/bankdocs/stamp/nbk.png',
  },
  [StampType.NIKO]: {
    label: 'Нико Банк',
    src: '/bankdocs/stamp/niko.png',
  },
  [StampType.OPEN]: {
    label: 'Открытие Банк',
    src: '/bankdocs/stamp/open.png',
  },
  [StampType.OTP]: {
    label: 'ОТП Банк',
    src: '/bankdocs/stamp/otp.png',
  },
  [StampType.POCHTA]: {
    label: 'Почта',
    src: '/bankdocs/stamp/pochta.png',
  },
  [StampType.PROC]: {
    label: 'Прокуратура',
    src: '/bankdocs/stamp/proc.png',
  },
  [StampType.PSBANK]: {
    label: 'PSB Банк',
    src: '/bankdocs/stamp/psbank.png',
  },
  [StampType.RAIFFEISEN]: {
    label: 'Raiffeisen Банк',
    src: '/bankdocs/stamp/raiffeisen.png',
  },
  [StampType.RENCREDIT]: {
    label: 'Ренессанс Кредит',
    src: '/bankdocs/stamp/rencredit.png',
  },
  [StampType.RNCB]: {
    label: 'РНКБ',
    src: '/bankdocs/stamp/rncb.png',
  },
  [StampType.ROSBANK]: {
    label: 'РосБанк',
    src: '/bankdocs/stamp/rosbank.png',
  },
  [StampType.ROSTRAH]: {
    label: 'РосГосСтрах',
    src: '/bankdocs/stamp/rostrah.png',
  },
  [StampType.RSB]: {
    label: 'Банк Русский Стандарт',
    src: '/bankdocs/stamp/rsb.png',
  },
  [StampType.RSHB]: {
    label: 'Рос Сельхоз Банк',
    src: '/bankdocs/stamp/rshb.png',
  },
  [StampType.SBER]: {
    label: 'Сбер Банк',
    src: '/bankdocs/stamp/sber.png',
  },
  [StampType.SETELEM]: {
    label: 'Сетелем Банк',
    src: '/bankdocs/stamp/setelem.png',
  },
  [StampType.SKBBANK]: {
    label: 'СКБ Банк',
    src: '/bankdocs/stamp/skbbank.png',
  },
  [StampType.SOVCOMBANK]: {
    label: 'СОВКОМБанк',
    src: '/bankdocs/stamp/sovcombank.png',
  },
  [StampType.TINKOFF]: {
    label: 'Тинькофф Банк',
    src: '/bankdocs/stamp/tinkoff.png',
  },
  [StampType.UBRR]: {
    label: 'УБРиР Банк',
    src: '/bankdocs/stamp/ubrr.png',
  },
  [StampType.UNICREDIT]: {
    label: 'ЮниКредит Банк',
    src: '/bankdocs/stamp/unicredit.png',
  },
  [StampType.URALSIB]: {
    label: 'Банк УралСиб',
    src: '/bankdocs/stamp/uralsib.png',
  },
  [StampType.VBR]: {
    label: 'Банк Возрождение',
    src: '/bankdocs/stamp/vbr.png',
  },
  [StampType.VTB]: {
    label: 'ВТБ Банк',
    src: '/bankdocs/stamp/vtb.png',
  },
  [StampType.ZAPSIB]: {
    label: 'ЗапСибКом Банк',
    src: '/bankdocs/stamp/zapsib.png',
  },
}
