import cls from './Header.module.scss'
import React from 'react'
import image from '../../assets/svg/image.svg'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { BankSelect } from './components/BankSelect/BankSelect'
import { useElementsContext } from '../../../../context/elementsContext'
import { classNames } from '../../../../../../utils/classNames'
import ButtonUi from '../../../../../../components/ui/ButtonUi/ButtonUi'
import { useLoadSpinContext } from '../../../../../../context/loadSpinContext'
import { ElementType, ITemplate } from '../../../../../../interfaces/templates.interface'

interface HeaderProps {
  item: ITemplate
  className?: string
  selected?: number
  setSelected: React.Dispatch<React.SetStateAction<number | undefined>>
}

export const Header: React.FC<HeaderProps> = ({ item, selected, className = '' }) => {
  const { setCurrentItem } = useElementsContext()
  const { setLoad } = useLoadSpinContext()

  const addTextHandler = () => {
    setCurrentItem((prev) => {
      const id = Date.now()
      return {
        ...prev,
        template: [
          ...prev.template,
          {
            id,
            label: String(id),
            value: 'TEXT',
            type: ElementType.TEXT,
            hidden: false,
            width: 10,
            x: 10,
            y: 10,
          },
        ],
      }
    })
  }

  const addImgHandler = () => {
    setCurrentItem((prev) => {
      const id = Date.now()
      return {
        ...prev,
        template: [
          ...prev.template,
          {
            id,
            label: String(id),
            value: image,
            type: ElementType.IMG,
            hidden: false,
            width: 40,
            x: 10,
            y: 10,
          },
        ],
      }
    })
  }

  const deletHandler = () => {
    setCurrentItem((prev) => ({
      ...prev,
      template: prev.template.filter((el) => el.id !== selected),
    }))
  }

  const printDocument = () => {
    const input = document.getElementById('divToPrint')
    if (input) {
      setLoad(true)
      html2canvas(input, {
        scale: 5,
        allowTaint: true,
        useCORS: true,
      })
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/jpeg')
          const pdf = new jsPDF()
          pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297, 'SLOW')
          pdf.save('download.pdf')
        })
        .finally(() => setLoad(false))
    }
  }

  return (
    <div className={classNames([cls.Header, className])}>
      <div className={cls.row}>
        <ButtonUi onClick={addTextHandler}>ТЕКСТ</ButtonUi>
        <ButtonUi onClick={addImgHandler}>КАРТИНКА</ButtonUi>
        <ButtonUi color='red' onClick={deletHandler}>
          УДАЛИТЬ
        </ButtonUi>
        <ButtonUi color='green' onClick={printDocument}>
          ПЕЧАТЬ
        </ButtonUi>
      </div>
      <div className={cls.row}>
        <BankSelect />
      </div>
    </div>
  )
}
