import { BaseDefaultStatusesColumn } from '../../enums/base'
import { useAppSelector } from '../hooks'

export const useStatusesSelector = () =>
  useAppSelector((state) => {
    const newArray = [...state.settings.statuses]
    return newArray.sort((a, b) => a.order - b.order)
  })

export const useVisibleStatusesSelector = () =>
  useAppSelector((state) => {
    const newArray = [...state.settings.statuses]
    return newArray
      ?.filter((el) => !el.isHidden || el.name === BaseDefaultStatusesColumn.REMAINDER)
      ?.sort((a, b) => a.order - b.order)
  })

export const useHiddenStatusesSelector = () =>
  useAppSelector((state) => {
    const newArray = [...state.settings.statuses]
    return newArray?.filter((el) => el.isHidden)?.sort((a, b) => a.order - b.order)
  })

export const useStatusesLoadingSelector = (id: string) =>
  useAppSelector((state) => Boolean(state.settings?.isStatusesLoading?.find((el) => el === id)))

export const useCountriesSelector = () => useAppSelector((state) => state.settings.countries)
export const useBanksSelector = () => useAppSelector((state) => state.settings.banks)

export const useFieldsSelector = () =>
  useAppSelector((state) => {
    return [...state.settings.fields]
  })

export const useVisibleFieldsSelector = () =>
  useAppSelector((state) => {
    const newArray = [...state.settings.fields]
    return newArray?.filter((el) => !el.isHidden)
  })

export const useHiddenFieldsSelector = () =>
  useAppSelector((state) => {
    const newArray = [...state.settings.fields]
    return newArray?.filter((el) => el.isHidden)
  })
