import { createSlice, Draft } from '@reduxjs/toolkit'
import { IDocBank } from '../../interfaces/templates.interface'
import { ICountry, IField, ISettingsStatus } from '../../interfaces/settings'

export interface ISettings {
  statuses: ISettingsStatus[]
  countries: ICountry[]
  banks: IDocBank[]
  fields: IField[]
  isStatusesLoading: string[]
}

const initialState: ISettings = {
  statuses: [],
  countries: [],
  banks: [],
  fields: [],
  isStatusesLoading: [],
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setStatuses: (state: Draft<ISettings>, { payload }) => {
      if (payload?.length) state.statuses = payload
    },
    deleteStatus: (state: Draft<ISettings>, { payload }) => {
      state.statuses = [...state.statuses].filter((el) => el.id !== payload)
    },
    setIsStatusesLoading: (state: Draft<ISettings>, { payload }) => {
      state.isStatusesLoading.push(payload)
    },
    removeIsStatusesLoading: (state: Draft<ISettings>, { payload }) => {
      state.isStatusesLoading = [...state.isStatusesLoading].filter((el) => el !== payload)
    },
    setCountries: (state: Draft<ISettings>, { payload }) => {
      if (payload?.length) state.countries = payload
    },
    setBanks: (state: Draft<ISettings>, { payload }) => {
      if (payload?.length) state.banks = payload
    },
    setFields: (state: Draft<ISettings>, { payload }) => {
      if (payload?.length) state.fields = payload
    },
  },
})

export const {
  setStatuses,
  setIsStatusesLoading,
  removeIsStatusesLoading,
  deleteStatus,
  setCountries,
  setBanks,
  setFields,
} = settingsSlice.actions

export default settingsSlice.reducer
