import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { SelectProps } from 'antd'
import SelectUi from '../SelectUi/SelectUi'
import { IDocMedia } from '../../../interfaces/templates.interface'
import { AxiosResponse } from 'axios'
import { serverPath } from '../../../constants/api-endpoints'
import { ImgType } from '../../../pages/DocGenPreview/components/DocEditor/constants/img'
import axiosInstance from '../../../config/axios'
interface SelectImgByTypeProps extends SelectProps {
  type: ImgType
  countryId?: string
  getOptions?: (options: IDocMedia[]) => void
  additionalValues?: { title: string; value: string }[]
}

export const SelectImgByType: React.FC<SelectImgByTypeProps> = ({
  getOptions,
  type,
  countryId,
  additionalValues,
  ...props
}) => {
  const [additionalImg, setAdditionalImg] = useState<IDocMedia[]>([])

  const imgList = useMemo(() => {
    return [
      ...(additionalImg?.map((el) => ({
        title: el.name,
        value: el.id,
      })) || []),
      ...(additionalValues || []),
    ]
  }, [additionalImg, additionalValues])

  const getMedia = useCallback(async () => {
    // if (countryId) {
    const { data }: AxiosResponse<{ rows: IDocMedia[]; count: number }> = await axiosInstance.get(
      serverPath.GENERATOR_MEDIA,
      {
        params: {
          type: type,
          page: 1,
          pageSize: 1000,
          // countryId: countryId,
        },
      },
    )
    setAdditionalImg(data?.rows)
    getOptions && getOptions(data?.rows)
    // }
  }, [type, countryId])

  useEffect(() => {
    getMedia().then()
  }, [getMedia])
  return (
    <>
      <SelectUi
        allowClear={false}
        placement='topLeft'
        showSearch
        // disabled={!countryId}
        filterOption={(input, option) =>
          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        values={imgList}
        {...props}
      />
      {/* {!countryId && <p className={cls.error}>Выберите страну</p>} */}
    </>
  )
}
