import { classNames } from '../../../../../../../../utils/classNames'
import { useElementsContext } from '../../../../../../context/elementsContext'
import cls from './LogoEdit.module.scss'
import React from 'react'
import { SliderUi } from '../../../../../../../../components/ui/SliderUi/SliderUi'
import { IElem } from '../../../../../../../../interfaces/templates.interface'

interface LogoEditProps {
  className?: string
  element: IElem
}

export const LogoEdit: React.FC<LogoEditProps> = ({ element, className = '' }) => {
  const { setCurrentItem } = useElementsContext()
  const imgUpdate = (val: string) => {
    setCurrentItem((prev) => {
      const filtered = prev.template.filter((el) => el.id !== element.id)
      return {
        ...prev,
        template: [
          ...filtered,
          {
            ...element,
            width: Number(val),
          },
        ],
      }
    })
  }

  const rotateUpdate = (val: string) => {
    setCurrentItem((prev) => {
      const filtered = prev.template.filter((el) => el.id !== element.id)
      return {
        ...prev,
        template: [
          ...filtered,
          {
            ...element,
            rotate: Number(val),
          },
        ],
      }
    })
  }

  const zIndexUpdate = (val: string) => {
    setCurrentItem((prev) => {
      const filtered = prev.template.filter((el) => el.id !== element.id)
      return {
        ...prev,
        template: [
          ...filtered,
          {
            ...element,
            zIndex: Number(val),
          },
        ],
      }
    })
  }

  return (
    <div className={classNames([cls.ImgEdit, className])}>
      <SliderUi
        label='Ширина:'
        min={5}
        max={100}
        onChange={(e) => imgUpdate(String(e))}
        value={element.width ?? 10}
      />
      <SliderUi
        label='Поворот:'
        min={0}
        max={360}
        onChange={(e) => rotateUpdate(String(e))}
        value={element.rotate ?? 0}
      />
      <SliderUi
        label='Высота слоя:'
        min={1}
        max={20}
        onChange={(e) => zIndexUpdate(String(e))}
        value={element.zIndex ?? 0}
      />
    </div>
  )
}
