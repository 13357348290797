import Select, { SelectProps } from 'antd/lib/select'
import React from 'react'
import cn from 'classnames'
import './SelectUi.scss'

const { Option } = Select

interface Props extends SelectProps {
  values: {
    value: string
    title: string
  }[]
}

const SelectUi: React.FC<Props> = ({ values, className, ...props }) => {
  return (
    <Select className={cn(className, 'customSelect')} {...props}>
      {values.map((elem, index) => (
        <Option value={elem.value} key={index + elem.value}>
          {elem.title}
        </Option>
      ))}
    </Select>
  )
}

export default SelectUi
